import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { OrderService, IProcutList } from "../../services/order.service";
import { Order } from "../../models/Order";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { ProductInOrder } from "src/app/models/ProductInOrder";
import { formatDate } from "@angular/common";
import localeIt from "@angular/common/locales/it";
import { Location } from "@angular/common";
import { UserService } from "src/app/services/user.service";
import { NotificationServiceService } from "src/app/services/notification.service.service";
import { Item } from "src/app/models/Item";
import { isNullOrUndefined } from "util";
import { Lightbox } from "ngx-lightbox";
import { apiUrl } from "../../../environments/environment";
import { DomSanitizer } from "@angular/platform-browser";
import { ImageSnippet } from "../aggungi-evento/aggungi-evento.component";
import { FarmacieService } from "src/app/services/farmacie.service";
import { ProductService } from "src/app/services/product.service";
import { CartService } from "src/app/services/cart.service";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { CouponService } from "src/app/services/coupon.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-order-detail",
  templateUrl: "./order-detail.component.html",
  styleUrls: ["./order-detail.component.css"],
})
export class OrderDetailComponent implements OnInit {
  order$: Observable<Order>;
  productList: ProductInOrder[];
  orderAmount = 0;
  deliveryData;
  name$;
  name: string;
  name1: string;
  orderId: string;
  emailProva: string;
  surnameOrder: string;
  surname1: string;
  minDate: string;
  prezzoMinimo: number;
  productFlag: boolean = false;
  priceFlag: boolean = false;
  desc: string;
  nome_prodotto: string;
  _albums: Array<any> = [];
  _productAlbums: Array<any> = [];
  order_status;
  dalleFarmacia;
  alleFarmacia;
  noteFarmacista = '';
  costoDelivery: number;
  blobArray: Array<Blob> = [];
  blobUrl;
  selectedFile: ImageSnippet;
  nomeProdotto: string;
  quantitaProdotto: number;
  quantitaProdottoListino: number;
  stringaModifica: string = "Modifica preventivo";
  tipoDelivery = [
    {
      tipo: "Domicilio",
    },
    { tipo: "Farmacia" },
  ];
  tipoConsegna: any;
  currentDate: Date = new Date();
  giftCard: string;
  corriere: string;
  aggiungiSlot: boolean = false;
  item: string = "";
  itemVolantino;
  page: Array<any>;
  nomeProdottoListino;
  listaProdotto: any[] = [];
  pageVolantino;
  noteSpedizione: string;
  promotionalCode: string;
  scontoOmaggio: any;
  used: boolean = false;
  maxConfirmTime: any;
  shipLabel = "Data consegna";
  constructor(
    private FarmacieService: FarmacieService,
    private orderService: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private userService: UserService,
    private notificationService: NotificationServiceService,
    private _lightbox: Lightbox,
    private sanitizer: DomSanitizer,
    private productService: ProductService,
    private cartService: CartService,
    private couponService: CouponService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.productService.getAllInPage(1, 9999).subscribe((x) => {
      this.listaProdotto = x.content;
    });
    this.minDate = formatDate(Date.now(), "yyyy-MM-dd", "it-IT");

    this.orderService
      .show(this.route.snapshot.paramMap.get("id"))
      .subscribe((x) => {
        this.emailProva = x.buyerEmail;
        this.name1 = x.buyerName;
        this.surname1 = x.buyerSurname;
        this.orderId = this.route.snapshot.paramMap.get("id");
        this.order_status = x.orderStatus;
        this.promotionalCode = x.promotionalCode;
        if (this.promotionalCode) {
          this.couponService.getStatusCoupon(this.promotionalCode).subscribe(
            (x) => { },
            (err) => {
              if (err.text == "Il coupon non è ancora stato utilizzato") {
                this.used = false;
              }
              if (err.text == "Il coupon è già stato utilizzato.") {
                this.used = true;
              }
            }
          );
        }
        if (this.order_status == 6) this.costoDelivery = 2.5;
        else this.costoDelivery = 0;
        this.userService.get(x.buyerEmail).subscribe((z) => {
              this.FarmacieService.getFarmacia(z.shop).subscribe((y) => {
              this.corriere = y.deliveryService;
              this.shipLabel = (this.order_status == 3 || this.order_status == 4 || this.order_status == 1 || this.order_status == 5)  ? "Data ritiro" : "Data consegna";
            });
          this.userService.giftcardbyid(z.idGiftCard).subscribe((x) => {
            this.giftCard = x.number ? x.number : " ";
        
          });
        });
      });

    /* this.orderService
        .allegatoRicette(this.route.snapshot.paramMap.get("id"))
        .subscribe((x) => {
          console.warn(x);
          this.urlAllegati = x.url;
        }); */
    const subs$ = this.orderService
      .show(this.route.snapshot.paramMap.get("id"))
      .subscribe((x) => {
        let products = x.products.map((x) => {
          x.oldproductPrice = x.productPrice;
          this.desc = x.productDescription;
          this.nome_prodotto = x.productName;
          x.available = x.available == null ? false : x.available;
          /*   x.dalle = this.dalleFarmacia;
          x.alle = this.alleFarmacia; */
          if (this.nome_prodotto.includes("downloadFile")) {
            let numberValue = parseInt(this.desc, 10);
            for (let i = 1; i <= numberValue; i++) {
              const src = `${apiUrl}/getBillImage/${this.route.snapshot.paramMap.get(
                "id"
              )}/${i}`;
              const descrizione = "allegato_" + i;
              const album = {
                src: this.sanitizer.bypassSecurityTrustUrl(src),
                descrizione: descrizione,
              };
              const blob = new Blob([src], {
                type: "application/octet-stream",
              });
              this.blobArray.push(blob);

              this._albums.push(album);
            }
          }

          return x;
        });

        /*   this.orderService.getBillImage(this.route.snapshot.paramMap.get("id"), this.desc).subscribe((answer) =>{
          this._albums = answer;
           }); */
        this.productList = products;
        /*       this.productList.forEach((element, index) => {
          if (element.productDescription.includes("mediaTemp")) {
            const src = element.productDescription;
            const descrizione = "immagine_" + index + ".jpg";
            const album = {
              src: src,
              descrizione: descrizione,
            };
            this._productAlbums.push(album);
          }
        }); */
        /*this.productList.forEach((element) => {
          if (element.productDescription == null) {
            element.productDescription = element.productId;
          }
        });*/
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
        }, 1000);
        subs$.unsubscribe();
      });
  }

  processFile(i) {
    /*  this.blobUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        window.URL.createObjectURL(this.blobArray[i])
      ); */

    const reader = new FileReader();

    return reader.readAsDataURL(this.blobArray[i]);
  }

  inviaPreventivo() {
    let dataConfronto = this.currentDate;
    dataConfronto.setUTCHours(-1, -1, -1);
    let productChanges = {};
    const dataTo = new Date(this.deliveryData);
    productChanges["orderId"] = parseInt(
      this.route.snapshot.paramMap.get("id"),
      10
    );
    productChanges["total"] = parseFloat(this.sum());
    productChanges["deliveryDate"] = formatDate(
      dataTo.getTime(),
      "yyyy-MM-ddTHH:mm:ss.840+0000",
      "it-IT"
    );
    productChanges["startDelivery"] = this.dalleFarmacia;
    productChanges["endDelivery"] = this.alleFarmacia;
    productChanges["products"] = [];
    this.productList.forEach((x) => {
      var price = x.productPrice ? x.productPrice.toFixed(2) : "0.00";
      if (x.productId != "Bill") {
        productChanges["products"].push({
          price: price,
          productId: x.productId,
          available: x.available,
          count: x.count,
          productDescription: x.productDescription,
        });
      }
      if (x.available == true) {
        this.productFlag = true;

        if (price == "0.00") {
          var r = confirm(
            "Attenzione, hai impostato ad un prodotto un costo zero. Premi ok se vuoi proseguire"
          );
          if (r == true) {
            this.priceFlag = false;
          } else {
            this.priceFlag = true;
          }
        }
      }
    });

    if (this.deliveryData == undefined) {
      this.productFlag = true;
      alert("Attenzione! La data deve essere valorizzata!");
    }
    if (this.dalleFarmacia >= this.alleFarmacia) {
      this.productFlag = false;
      alert(
        "Attenzione, il campo 'dalle' non può essere superiore o pari ad 'alle'"
      );
    }
    if (dataTo < dataConfronto) {
      this.productFlag = false;
      alert(
        "Attenzione, la data di spedizione non può essere precedente a quella di oggi"
      );
    }
    if (this.productFlag == true && this.priceFlag == false) {
      const sub1$ = this.orderService
        .changePrice(productChanges as IProcutList)
        //.toPromise()
        .subscribe((res) => {
          if (res) {
            const sub2$ = this.orderService
              .sendPreventivo(this.route.snapshot.paramMap.get("id"))
              .subscribe(
                (res) => {
                  if(this.noteFarmacista != null && this.noteFarmacista != ''){
                  this.orderService
                  .sendNotes(res.orderId,this.noteFarmacista)
                  .subscribe((asd) => {
                    this.router.navigate(["/orderbyemployee"]);
                  });
                }
                  this.router.navigate(["/orderbyemployee"]);
                },
                () => {
                  sub1$.unsubscribe();
                  sub2$.unsubscribe();
                },
                () => {
                  sub1$.unsubscribe();
                  sub2$.unsubscribe();
                }
              );
          }
        });

      /*       let token; */
      /*     this.orderService
            .show(this.route.snapshot.paramMap.get("id"))
            .subscribe((x) => {
              let email = x.buyerEmail;
              this.userService.get(email).subscribe((y) => {
                token = y.fbtoken;
                this.notificationService.invionotifica(
                  [token],
                  "Il preventivo relativo all'ordine n." +
                    this.orderId +
                    " è stato elaborato",
                  "Preventivo ordine n." + this.orderId
                );
              });
            }); */
    }
  }
  onBlurListino() {
    if (
      this.item &&
      this.quantitaProdottoListino &&
      this.item != undefined &&
      this.item != null
    ) {
      var r = confirm(
        "Sei sicuro di voler aggiungere il prodotto " +
        this.item +
        " alla lista?"
      );
      if (r == true) {
        let i = 0;
        let flag = true;

        /* do { */
        this.productList.forEach((element) => {
          if (
            this.item.replace(/\s/g, "") ==
            element.productName.replace(/\s/g, "")
          ) {
            flag = false;
          }
        });
        /*   i++; */
        /*         } while (flag || i <= this.productList.length); */
        if (flag) {
          /*    this.productList[0];
        let singleProduct = new ProductInOrder(this.item);
        singleProduct.productName = this.item;
        singleProduct.productDescription = this.page[i].codminsan;
        singleProduct.count = 1;
        singleProduct.productId = this.page[i].codice;
        singleProduct.productPrice = 0;
        singleProduct.available = true;
        let prodottoPerCarrello = {};
        prodottoPerCarrello["productId"] = singleProduct.productDescription;
        prodottoPerCarrello["count"] = singleProduct.count; */

          /*               let prodottoPerCarrello = {};
              prodottoPerCarrello["productId"] = x.productDescription;
              prodottoPerCarrello["count"] = x.count; */
          let tempFarm = new ProductInOrder(this.item);

          this.page
            .filter(
              (v) =>
                v.descrizione.toLowerCase().indexOf(this.item.toLowerCase()) >
                -1
            )
            .slice(0, 1)
            .forEach((element) => {
              tempFarm.productName = element.descrizione;
              tempFarm.productDescription = element.codminsan;
              tempFarm.productId = element.codice;
              tempFarm.productPrice = 0;
              tempFarm.available = true;
              tempFarm.count = this.quantitaProdottoListino;
            });
          this.cartService
            .addProductToOrder(tempFarm, this.route.snapshot.paramMap.get("id"))
            .subscribe((z: any) => {
              this.productList.push(z);
              this.item = "";
              this.quantitaProdottoListino = 0;
            });
        } else {
          alert("Attenzione, prodotto già presente nel carrello!");
          this.item = "";
        }
      }
    }
  }
  onBlurSconto() {
    if (this.scontoOmaggio != null) {
      var r = confirm("Sei sicuro di voler aggiungere lo sconto promo? ");
      if (r) {
        /*   if (parseFloat(this.scontoOmaggio) == NaN) {
          this.scontoOmaggio = "-" + this.scontoOmaggio;
        } */
        let asd: string = this.scontoOmaggio;
        let stringToCompare =
          parseFloat(this.scontoOmaggio) > 0.1
            ? parseFloat(this.scontoOmaggio)
            : this.scontoOmaggio;

        let i = 0;
        let flag = true;

        asd = asd.toString();
        let tempFarm = new ProductInOrder("Promo_applicata", 1);
        tempFarm.available = true;
        tempFarm.productId = "999999";
        tempFarm.productName = asd;
        tempFarm.productDescription = "Promo applicata";
        tempFarm.productPrice =
          typeof stringToCompare === "number" ? stringToCompare : 0;

        this.cartService
          .addProductToOrder(tempFarm, this.route.snapshot.paramMap.get("id"))
          .subscribe((z) => {
            this.productList.push(tempFarm);
            this.scontoOmaggio = "";
          });
      }
    }
  }
  onBlurVolantino() {
    if (
      this.itemVolantino &&
      this.quantitaProdotto &&
      this.itemVolantino != undefined &&
      this.itemVolantino != null
    ) {
      var r = confirm(
        "Sei sicuro di voler aggiungere il prodotto " +
        this.itemVolantino +
        " alla lista?"
      );
      if (r == true) {
        let i = 0;
        let flag = true;

        /* do { */
        this.productList.forEach((element) => {
          if (
            this.itemVolantino.replace(/\s/g, "") ==
            element.productName.replace(/\s/g, "")
          ) {
            flag = false;
          }
        });
        /*   i++; */
        /*         } while (flag || i <= this.productList.length); */
        if (flag) {
          /*    this.productList[0];
        let singleProduct = new ProductInOrder(this.item);
        singleProduct.productName = this.item;
        singleProduct.productDescription = this.page[i].codminsan;
        singleProduct.count = 1;
        singleProduct.productId = this.page[i].codice;
        singleProduct.productPrice = 0;
        singleProduct.available = true;
        let prodottoPerCarrello = {};
        prodottoPerCarrello["productId"] = singleProduct.productDescription;
        prodottoPerCarrello["count"] = singleProduct.count; */

          /*               let prodottoPerCarrello = {};
              prodottoPerCarrello["productId"] = x.productDescription;
              prodottoPerCarrello["count"] = x.count; */
          let tempFarm: ProductInOrder = null;

          this.listaProdotto
            .filter(
              (v) =>
                v.productName
                  .toLowerCase()
                  .indexOf(this.itemVolantino.toLowerCase()) > -1
            )
            .slice(0, 1)
            .forEach((element) => {
              tempFarm = element;
            });
          tempFarm.count = this.quantitaProdotto;
          this.cartService
            .addProductToOrder(tempFarm, this.route.snapshot.paramMap.get("id"))
            .subscribe((z) => {
              this.productList.push(tempFarm);
              this.itemVolantino = "";
              this.quantitaProdotto = 0;
            });
        } else {
          alert("Attenzione, prodotto già presente nel carrello!");
          this.itemVolantino = "";
        }
      }
    }
  }
  /* onBlurProdotto() {
    this.page = [];
    this.productService
      .getAllProdotti(this.nomeProdotto.replace(/ /g, "_"))
      .subscribe((x) => {
        this.page = x;
        this.item = this.page[0].descrizione;
      });
  } */
  modificaPreventivo() {
    this.aggiungiSlot = !this.aggiungiSlot;
    if (this.aggiungiSlot) {
      this.stringaModifica = "Salva";
    } else {
      this.stringaModifica = "Modifica preventivo";
    }
  }
  inviaPreventivoToSeven() {
    let dataConfronto = this.currentDate;
    dataConfronto.setUTCHours(-1, -1, -1);
    let dateToComparare = new Date();
    let time = dateToComparare.getHours();
    const dataTo = new Date(this.deliveryData);
    let day = dataTo.getDay();
    let flag = false;
    let dateToCompare;
    let dateMonth = dataTo.getMonth() + 1;
    if (dateMonth < 9) {
      dateToCompare = dataTo.getDate() + "/0" + dateMonth;
    } else if (dateMonth >= 10) {
      dateToCompare = dataTo.getDate() + "/" + dateMonth;
    }

    if (
      dateToCompare == "25/12" ||
      dateToCompare == "26/12" ||
      dateToCompare == "1/01" ||
      dateToCompare == "6/01" ||
      dateToCompare == "25/04" ||
      dateToCompare == "2/06" ||
      dateToCompare == "15/08"
    ) {
      flag = true;
      alert("Attenzione, non è possibile fare consegne nei giorni festivi.");
    }
    if ((day == 0 || day == 6) && this.corriere == "GLS") {
      flag = true;
      alert(
        "Attenzione! Non è possibile effettuare consegne di Sabato e di Domenica."
      );
    }
    if (this.deliveryData == undefined) {
      flag = true;
      alert("Attenzione! La data deve essere valorizzata!");
    }
    if (
      this.corriere == "GLS" &&
      time >= 12 &&
      dataTo.getDate() == dateToComparare.getDate()
    ) {
      flag = true;
      alert(
        "Attenzione! Dopo le 12 non è possibile effettuare consegne in giornata."
      );
    }

    let productChanges = {};
    if (!flag) {
      if(this.corriere != "GLS"){
         productChanges["maxConfirmTime"] = this.maxConfirmTime;
         productChanges["startDelivery"] = this.dalleFarmacia;
         productChanges["endDelivery"] = this.alleFarmacia;
    }
      productChanges["orderId"] = parseInt(
        this.route.snapshot.paramMap.get("id"),
        10
      );
      productChanges["total"] = parseFloat(this.sum());
      productChanges["deliveryDate"] = formatDate(
        dataTo.getTime(),
        "yyyy-MM-ddTHH:mm:ss.840+0000",
        "it-IT"
      );
   

      productChanges["products"] = [];
      this.productList.forEach((x) => {
        var price = x.productPrice ? x.productPrice.toFixed(2) : "0.00";
        if (x.productId != "Bill") {
          productChanges["products"].push({
            price: price,
            productId: x.productId,
            available: x.available,
            count: x.count,
            productDescription: x.productDescription,
          });
        }
        if (x.available == true) {
          this.productFlag = true;

          if (price == "0.00") {
            var r = confirm(
              "Attenzione, hai impostato ad un prodotto un costo zero. Premi ok se vuoi proseguire"
            );
            if (r == true) {
              this.priceFlag = false;
            } else {
              this.priceFlag = true;
            }
          }
        }
      });
      productChanges["products"].push({
        price: this.costoDelivery.toString(),
        productId: "001122334",
        productName: "Spese di spedizione",
        count: 1,
        available: true,
        productDescription: "Spese di spedizione",
      });
      //console.log(productChanges);
      /* if (this.costoDelivery != 0) {
         let productDelivery = new ProductInOrder("costo spedizione");
         productDelivery.productPrice = this.costoDelivery;
         productDelivery.productName = "costo spedizione";
         productDelivery.productId = "";
         productDelivery.productDescription = "";
         this.productList.push(productDelivery);
       }*/
      if (dataTo < dataConfronto) {
        this.productFlag = false;
        alert(
          "Attenzione, la data di spedizione non può essere precedente a quella di oggi"
        );
      }
      if (this.productFlag == true && this.priceFlag == false) {
        const sub1$ = this.orderService
          .changePrice(productChanges as IProcutList)
          //.toPromise()
          .subscribe((res) => {
            if (this.noteSpedizione != null) {
              this.orderService
                .sendNotes(
                  this.route.snapshot.paramMap.get("id"),
                  this.noteSpedizione
                )
                .subscribe((y) => { });
            }
            if (res) {
              const sub2$ = this.orderService
                .sendPreventivoToSeven(this.route.snapshot.paramMap.get("id"))
                .subscribe(
                  (res) => {
                    if(this.noteFarmacista != null && this.noteFarmacista != ''){
                      this.orderService
                      .sendNotes(res.orderId,this.noteFarmacista)
                      .subscribe((asd) => {
                        this.router.navigate(["/orderbyemployee"]);
                      });
                    }
                    this.router.navigate(["/orderbyemployee"]);
                  },
                  () => {
                    sub1$.unsubscribe();
                    sub2$.unsubscribe();
                  },
                  () => {
                    sub1$.unsubscribe();
                    sub2$.unsubscribe();
                  }
                );
            }
          });

        /*     let token;
            this.orderService
              .show(this.route.snapshot.paramMap.get("id"))
              .subscribe((x) => {
                let email = x.buyerEmail;
                this.userService.get(email).subscribe((y) => {
                  token = y.fbtoken;
                  this.notificationService.invionotifica(
                    [token],
                    "Il preventivo relativo all'ordine n." +
                      this.orderId +
                      " è stato elaborato",
                    "Notifica preventivo ordine n." + this.orderId
                  );
                });
              }); */
      }
    }
  }
  annullaPreventivo(dom = "Sicuro di voler annullare il preventivo?") {
    var domanda = confirm(dom);
    if (domanda == true) {
      this.orderService
        .nullPreventivo(this.route.snapshot.paramMap.get("id"))
        .subscribe((res) => {
          this.router.navigate(["/orderbyemployee"]);
        });
      /*  let token;
       this.orderService
         .show(this.route.snapshot.paramMap.get("id"))
         .subscribe((x) => {
           let email = x.buyerEmail;
           this.userService.get(email).subscribe((y) => {
             token = y.fbtoken;
             this.notificationService.invionotifica(
               [token],
               "il preventivo relativo all'ordine n." +
                 this.orderId +
                 " è stato annullato",
               "Notifica preventivo ordine n." + this.orderId
             );
           });
         }); */
    }
  }
  removeProduct(i) {
    var r = confirm(
      "Sei sicuro di voler eliminare il prodotto " +
      this.productList[i].productName +
      "?"
    );
    if (r == true) {
      this.cartService
        .deleteProductToOrder(
          this.route.snapshot.paramMap.get("id"),
          this.productList[i].productId
        )
        .subscribe((x) => {
          this.productList.splice(i, 1);
          alert("Prodotto rimosso correttamente dal carrello!");
        });
    }
  }

  sum(): string {
    try {
      let amount = 0.0;
      amount += this.costoDelivery;

      this.productList.forEach((c) => {
        if (c.available) {
          if (c.productId == "999999") {
            amount += -c.productPrice * c.count;
          } else {
            amount += c.productPrice * c.count;
          }
        }
      });
      /*  if (amount < 0) amount = 0; */
      return amount.toFixed(2).toString();
    } catch (err) { }
  }

  setproductPrice(price: number) {
    if (price === null) {
      this.prezzoMinimo = 0;
    }
    this.prezzoMinimo = price;
  }
  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }
  /*  openImageProduct(path): void {
    let i;
    this._productAlbums.forEach((element, index) => {
      if (path == element.src) {
        i = index;
      }
    });

    this._lightbox.open(this._productAlbums, i);
  } */

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  onKeyup() {
    let wordSearch = this.nomeProdottoListino;
    setTimeout(() => {
      if (wordSearch == this.nomeProdottoListino) {
        if (this.nomeProdottoListino) {
          this.onBlurProdottoListino();
        }
      }
    }, 3000);
    /*  var timer = setTimeout(() => {
      this.onBlurProdottoListino();
    }, 3000); */
  }
  onKeyupVolantino() {
    let wordSearch = this.nomeProdotto;
    setTimeout(() => {
      if (wordSearch == this.nomeProdotto) {
        if (this.nomeProdotto) {
          this.onBlurProdottoVolantino();
        }
      }
    }, 3000);
    /*   var timer = setTimeout(() => {
      this.onBlurProdottoVolantino();
    }, 3000); */
  }
  onBlurProdottoListino() {
    this.page = [];
    let cont = 0;

    do {
      this.productService;
      cont++;
      this.productService
        .getAllProdotti(this.nomeProdottoListino.replace(/ /g, "_"), cont)
        .subscribe((x) => {
          if (x != []) {
            x.forEach((element) => {
              this.page.push(element);
            });
          } /* .slice(0, 5); */
          this.item = this.page[0].descrizione;
        });
    } while (cont <= 9);

    /*  for (var i = 0; i < tempPage.length; i++) {
      this.page = this.page.concat(tempPage[i]);
    } */
  }

  /*  searchProductListino = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) => (term === "" ? [] : this.searchProductsListino(term)))
    );

  onSearchChange(searchValue: string) {
    this.productService
      .getAllProdotti(searchValue.replace(/ /g, "_"))
      .subscribe((x) => {
        this.page = x;
        this.item = this.page[0].descrizione;
      });
  }
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  } */

  onBlurProdottoVolantino(): any {
    let tempFarm: any = [];

    this.listaProdotto
      .filter(
        (v) =>
          v.productName.toLowerCase().indexOf(this.nomeProdotto.toLowerCase()) >
          -1
      )
      /*     .slice(0, 5) */
      .forEach((element) => {
        tempFarm.push(element.productName);
      });
    this.pageVolantino = tempFarm;
    this.itemVolantino = this.pageVolantino[0];
  }

  /* searchProduct = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) => (term === "" ? [] : this.searchProducts(term)))
    ); */
  searchProducts(term) {
    let tempFarm: any = [];
    this.listaProdotto
      .filter(
        (v) => v.productName.toLowerCase().indexOf(term.toLowerCase()) > -1
      )
      /* .slice(0, 5) */
      .forEach((element) => {
        tempFarm.push(element.productName);
      });
    return tempFarm;
  }


}
